<template>
  <v-card
    centered
    hide-footer
    id="forgotModal"
    ref="forgotModal"
    title="Forgot Password"
  >
    <v-card-title class="headline white">
      Forget Password
    </v-card-title>
    <template slot="modal-header" slot-scope="{ close }">
      <!-- Emulate built in modal header close button action -->
      <h5>Forgot Password</h5>
      <img
        @click="close()"
        src="../../assets/images/dashboard_icons/close_button.svg"
      />
    </template>
    <div
      class="alert alert-success no-display text-center"
      id="forget_msg"
    ></div>
    <v-form
      class="pb-4 pt-3"
      @submit="onForgetSubmit"
      v-model="valid"
      method="post"
    >
      <div class="form-group">
        <v-text-field
          id="login-email-input"
          autofocus
          class="ml-8 mr-8"
          v-model="forgotEmail"
          :rules="emailRules"
          label="Email Address"
          append-icon="mdi-email"
          required
          autocomplete="off"
        ></v-text-field>
      </div>
      <!-- <button class="btn btn-theme" ref="submitButton" type="submit">SEND</button> -->

      <v-btn
        color="#2758f6"
        width="80%"
        :x-large="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
        :medium="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        type="submit"
        class="white--text text-capitalize text-white mt-5"
        :class="
          $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
            ? 'subtitle-1'
            : 'title'
        "
        :disabled="!valid"
      >
        <v-progress-circular indeterminate v-if="forgetLoader" size="20">
        </v-progress-circular>
        SEND</v-btn
      >
    </v-form>
  </v-card>
</template>

<script>
import { emailRules, required } from "@/Constants/Const";
export default {
  name: "ForgetPassword",
  data() {
    return {
      forgotEmail: "",
      emailRules: emailRules,
      valid: true,
      forgetLoader: false,
    };
  },
  props: ["forget_dialog"],
  methods: {
    onForgetSubmit(event) {
      event.preventDefault();
      this.forgetLoader = true;
      let data = { email: this.forgotEmail };
      this.$store
        .dispatch("custom/forgotPassword", data)
        .then((response) => {
          this.forgetLoader = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: response.data.error_msg,
            snackbarColor: "green",
          });
          this.$eventBus.$emit("forget_dialog",false);
          // this.$refs.forgotModal.hide();
        })
        .catch((error) => {
          this.forgetLoader = false;
          if (error.response.data.errors) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.errors.email[0],
              snackbarColor: "red",
            });
          }
          else if (error.response.data.error) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg[0],
              snackbarColor: "red",
            });
          } else {
            if (error.response.data.error == true) {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.message,
                snackbarColor: "red",
              });
            }
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
